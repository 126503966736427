import React from 'react';
import TermsOfUse from '../components/organisms/TermsOfUse/TermsOfUse';
import Layout from '../components/layout';

type Props = {
	path: string;
};
const TermsOfUsePage = ({ path }: Props) => {
	return (
		<Layout>
			<TermsOfUse path={path} />
		</Layout>
	);
};

export default TermsOfUsePage;
