// extracted by mini-css-extract-plugin
export var adaptiveText = "style-module--adaptiveText--CI1le";
export var chromeframe = "style-module--chromeframe--nyZ6H";
export var errorText = "style-module--errorText--6+1Fy";
export var overlay = "style-module--overlay--wZiF3";
export var terms_of_use = "style-module--terms_of_use--o6cpg";
export var terms_of_use__cont = "style-module--terms_of_use__cont--WpFSj";
export var textDefault = "style-module--text-default--PEZ-T";
export var textInput = "style-module--text-input--pKiFc";
export var videoJs = "style-module--video-js--QuP-B";
export var vjsBigPlayButton = "style-module--vjs-big-play-button--78xKr";
export var vjsIconPlaceholder = "style-module--vjs-icon-placeholder--W7LHP";
export var vjsPoster = "style-module--vjs-poster--lmVdT";